<template>
  <div class="page-index">
    <div class="frame-container">
      <div v-loading="loading" class="sign-frame frame-right">
        <h1 style="margin-bottom: 50px">{{ $common.WEBSITE_NAME }}</h1>
        <el-form ref="form" :model="form" label-width="50px">
          <el-form-item class="sign-form" label="账号:">
            <el-input
                v-model.trim="form.username"
                placeholder="请输入账号/邮箱"
                @keyup.enter.native="signIn"
            ></el-input>
          </el-form-item>
          <el-form-item class="sign-form" label="密码:">
            <el-input
                v-model.trim="form.password"
                placeholder="请输入密码"
                show-password
                @keyup.enter.native="signIn"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="login-btn" type="primary" @click="signIn" round
            >登录
            </el-button
            >
          </el-form-item>
        </el-form>
        <h5>{{ version }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignIn",
  data: function () {
    return {
      loading: false,
      form: {
        username: "",
        password: "",
      },
      version: process.env.VUE_APP_VERSION,
    };
  },
  methods: {
    signIn: function () {
      let _me = this;
      let f = _me.form;
      if (f.username.length == 0) {
        _me.$message.error("请输入正确的账号信息");
        return;
      }
      if (f.password.length == 0) {
        _me.$message.error("请输入正确的密码信息");
        return;
      }

      let params = {
        username: f.username,
        password: _me.$md5(f.password),
      };
      _me.loading = true;
      _me.api
          .login(params)
          .then((res) => {
            _me.loading = false;
            if (res.code == "1000000") {
              _me.$common.setGlobalStorage("_user", res.data, 60 * 24 * 30);
              _me.$router.push("/");
            } else {
              _me.$message({
                message: res.message || "获取数据失败",
                type: "error",
              });
            }
          })
          .catch((err) => {
            _me.loading = false;
            if (err && err.message) {
              _me.$message.error("登录出现错误:" + err.message);
            }
          });
    },
  },
};
</script>

<style scoped>
.page-index {
  background: url(../assets/login.jpeg);
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-position: center;
  background-attachment: fixed;
}

.frame-container {
  width: 500px;
  padding: 0 20px;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  right: 50px;
  top: 45%;
  transform: translate(0, -50%);
  border-radius: 50px;
}

.frame-left {
  width: 50%;
  max-height: calc(100vh - 200px);
  object-fit: contain;
}

.sign-form {
  margin-right: 50px;
  padding-left: 20px;
}

.login-btn {
  width: 380px;
  margin-left: -30px;
}

.copyright-text {
  width: 100%;
  height: 100px;
  line-height: 100px;
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  color: #000;
}
</style>
