<template>
  <div v-loading="loading">
    <el-container class="g-home">
      <el-header class="header-long-global">
        <img
          src="https://res.morfbar.com/www/files/v1/97a2xym6_morfbar_logo.png"
          class="header-logo"
          alt="logo"
        />
        <div v-if="$route.path != '/sign-in'" class="user-info">
          <span class="info-text">
            <span class="info-text-row">{{ userInfo.username }}</span>
            <span class="info-text-row">{{ userInfo.email }}</span>
          </span>
          <el-dropdown trigger="click" @command="handleCommand">
            <el-avatar
              shape="circle"
              icon="el-icon-user-solid"
              :src="avatar"
            ></el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">修改密码</el-dropdown-item>
              <el-dropdown-item command="2">关于系统</el-dropdown-item>
              <el-dropdown-item command="3">退出登录</el-dropdown-item>
              <el-dropdown-item command="4">清除缓存</el-dropdown-item>
              <el-dropdown-item
                command="5"
                v-if="userInfo.type && userInfo.type === 99"
                >更新接口</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container class="g-home_container">
        <el-aside width="200px">
          <el-menu
            class="header-main-menu"
            :default-active="
              $route.path
                ? $route.path.substr(1)
                : aside_list[0].children[0].path
            "
            background-color="#3a4b69"
            text-color="#fff"
            mode="vertical"
            active-text-color="#03aef9"
            router
            :collapse="isCollapse"
          >
            <template v-for="(item, index) in aside_list">
              <el-submenu
                :index="index + ''"
                :key="index"
                v-show="item.name"
                v-if="item.catalogue"
              >
                <template slot="title">
                  <i :class="item.icon"></i>
                  <span>{{ item.name }}</span>
                </template>
                <div
                  v-for="child in item.children"
                  :key="child.path"
                  v-show="child.name && child.isShow"
                >
                  <div v-if="child.children">
                    <el-submenu
                      :index="child.path"
                      v-show="child.name && child.isShow"
                    >
                      <template slot="title">{{ child.name }}</template>
                      <el-menu-item
                        v-for="v in child.children"
                        :index="v.path"
                        :key="v.path"
                        v-show="v.name && v.isShow"
                        >{{ v.name }}</el-menu-item
                      >
                    </el-submenu>
                  </div>
                  <div v-else>
                    <!-- <span v-if="type != child.type"> -->
                    <el-menu-item
                      :index="child.path"
                      v-show="child.name && child.isShow"
                      >{{ child.name }}</el-menu-item
                    >
                    <!-- </span> -->
                  </div>
                </div>
              </el-submenu>
              <el-menu-item
                :index="item.path.slice(1)"
                v-else
                :key="index + '1'"
              >
                <i :class="item.icon"></i>
                <span slot="title">{{ item.name }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </el-aside>
        <el-main class="header-main-frame">
          <keep-alive>
            <router-view
              v-if="$route.meta.keepAlive"
              :key="$route.fullPath"
            ></router-view>
          </keep-alive>
          <router-view
            v-if="!$route.meta.keepAlive"
            :key="$route.fullPath"
          ></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
const route_list = [
  {
    path: "/webConfig",
    title: "/web/config",
    name: "配置管理",
    icon: "el-icon-s-tools",
    catalogue: false,
    children: [
      {
        path: "webConfig",
        name: "配置管理列表",
        isShow: true,
      },
      {
        path: "webConfig-detail",
        name: "配置详情",
        isShow: true,
      },
    ],
  },
  {
    path: "/",
    title: "/product",
    name: "产品管理",
    icon: "el-icon-shopping-bag-2",
    catalogue: true,
    children: [
      {
        path: "product-type",
        name: "产品类型",
        isShow: true,
      },
      {
        path: "product-category-detail",
        name: "产品类型详情",
        isShow: false,
      },
      {
        path: "product",
        name: "产品列表",
        isShow: true,
      },
      {
        path: "product-detail",
        name: "产品详情",
        isShow: false,
      },
      {
        path: "product-detail-category",
        name: "详情分类",
        isShow: true,
      },
      {
        path: "product-detail-category-detail",
        name: "详情分类详情",
        isShow: false,
      },
      {
        path: "product-detail-template-rich-text",
        name: "产品详情富文本",
        isShow: false,
      },
    ],
  },
  {
    path: "/",
    title: "/banner",
    name: "横幅管理",
    icon: "el-icon-files",
    catalogue: true,
    children: [
      {
        path: "banner-list",
        name: "横幅列表",
        isShow: true,
      },
      {
        path: "banner-detail",
        name: "横幅详情",
        isShow: false,
      },
      {
        path: "banner-class-list",
        name: "横幅分类列表",
        isShow: true,
      },
      {
        path: "banner-class-detail",
        name: "横幅分类详情",
        isShow: false,
      },
    ],
  },
  {
    path: "/content",
    title: "/content",
    name: "内容管理",
    icon: "el-icon-s-promotion",
    catalogue: true,
    children: [
      {
        path: "content",
        name: "文章管理",
        isShow: true,
      },
      {
        path: "content-detail",
        name: "文章管理详情",
        isShow: false,
      },
      {
        path: "content-category",
        name: "文章分类",
        isShow: true,
      },
      {
        path: "content-category-detail",
        name: "文章分类详情",
        isShow: false,
      },
      {
        path: "content-type",
        name: "文章类型",
        isShow: true,
      },
      {
        path: "content-type-detail",
        name: "文章类型详情",
        isShow: false,
      },
    ],
  },
  {
    path: "/file",
    title: "/file",
    name: "文件管理",
    icon: "el-icon-folder-opened",
    redirect: "file",
    catalogue: false,
    children: [
      {
        path: "file",
        name: "文件",
        isShow: true,
      },
    ],
  },
  {
    path: "/",
    title: "/root/auth",
    name: "权限管理",
    icon: "el-icon-user",
    catalogue: true,
    children: [
      {
        path: "admin",
        name: "管理员列表",
        isShow: true,
      },
      {
        path: "admin-detail",
        name: "管理员详情",
        isShow: false,
      },
      {
        path: "admin-auth-role",
        name: "角色列表",
        isShow: true,
      },
      {
        path: "admin-auth-role-detail",
        name: "角色详情",
        isShow: false,
      },
      {
        path: "admin-auth-api",
        name: "权限列表",
        isShow: true,
      },
      {
        path: "admin-auth-api-detail",
        name: "权限详情",
        isShow: false,
      },
      {
        path: "admin-auth-category",
        name: "权限分类",
        isShow: true,
      },
      {
        path: "admin-auth-category-detail",
        name: "权限分类",
        isShow: false,
      },
    ],
  },
  {
    path: "/account-list",
    title: "/user/admin",
    name: "账号管理",
    icon: "el-icon-s-custom",
    catalogue: false,
    children: [
      {
        path: "account-list",
        name: "账号",
        isShow: true,
      },
      {
        path: "account-detail-list",
        name: "账号详情",
        isShow: false,
      },
      {
        path: "user-update-password",
        name: "修改密码",
        isShow: false,
      },
      {
        path: "about-system",
        name: "关于系统",
        isShow: false,
      },
    ],
  },
  {
    path: "/code-list",
    title: "/anti/fake/admin",
    name: "验真码管理",
    icon: "el-icon-key",
    catalogue: true,
    children: [
      {
        path: "code-batch-list",
        name: "验真码批次",
        isShow: true,
      },
      {
        path: "code-batch-detail",
        name: "验真码批次详情",
        isShow: false,
      },
      {
        path: "code-list",
        name: "验真码列表",
        isShow: true,
      },
      {
        path: "code-detail",
        name: "验真码详情",
        isShow: false,
      },
      {
        path: "code-statistics",
        name: "验真码绑定数量列表",
        isShow: true,
      },
      //   {
      //     path: "code-batch-list",
      //     name: "验真码批次管理",
      //     isShow: true,
      //   },
      {
        path: "code-upload",
        name: "上传记录",
        isShow: true,
      },
      {
        path: "code-upload-detail",
        name: "验真码上传记录详情",
        isShow: false,
      },
      {
        path: "code-upload-err",
        name: "上传错误记录",
        isShow: true,
      },
      {
        path: "code-record-list",
        name: "查询记录",
        isShow: true,
      },
      {
        path: "code-record-detail",
        name: "查询记录详情",
        isShow: false,
      },
      {
        path: "code-rules-list",
        name: "查询规则",
        isShow: true,
      },
      {
        path: "code-rules-detail",
        name: "查询规则详情",
        isShow: false,
      },
      {
        path: "code-record-map",
        name: "查询记录地图",
        isShow: true,
      },
    ],
  },
  {
    path: "/global-partners",
    title: "/brand/global-partners",
    name: "品牌管理",
    icon: "el-icon-office-building",
    catalogue: true,
    children: [
      {
        path: "global-partners",
        name: "全球合作伙伴",
        isShow: true,
        children: [
          {
            path: "global-partners",
            name: "伙伴列表",
            isShow: true,
          },
          {
            path: "global-partners-detail",
            name: "伙伴列表详情",
            isShow: false,
          },
          {
            path: "global-partners-region",
            name: "地区列表",
            isShow: true,
          },
          {
            path: "global-partners-region-detail",
            name: "地区列表详情",
            isShow: false,
          },
          {
            path: "global-partners-state",
            name: "州列表",
            isShow: true,
          },
          {
            path: "global-partners-state-detail",
            name: "州列表详情",
            isShow: false,
          },
          {
            path: "global-partners-product",
            name: "产品列表",
            isShow: true,
          },
          {
            path: "global-partners-product-detail",
            name: "产品列表详情",
            isShow: false,
          },
        ],
      },
    ],
  },
  {
    path: "/seo-list",
    title: "/seo/admin",
    name: "SEO管理",
    icon: "el-icon-s-platform",
    catalogue: true,
    children: [
      {
        path: "seo-list",
        name: "站点地图",
        isShow: true,
      },
      {
        path: "seo-detail",
        name: "站点地图详情",
        isShow: false,
      },
    ],
  },
  {
    path: "/log-nginx-list",
    title: "/log/admin",
    name: "日志管理",
    icon: "el-icon-data-line",
    catalogue: true,
    children: [
      {
        path: "log-nginx-log-list",
        name: "Nginx日志列表",
        isShow: true,
      },
      {
        path: "log-nginx-log-chart",
        name: "Nginx日志图表",
        isShow: true,
      },
      {
        path: "log-nginx-valid-data-list",
        name: "Nginx有效列表",
        isShow: true,
      },
      {
        path: "log-nginx-valid-data-chart",
        name: "Nginx有效图表",
        isShow: true,
      },
    ],
  },
  {
    path: "/lottery-list",
    title: "/activity",
    name: "抽奖管理",
    icon: "el-icon-medal-1",
    catalogue: true,
    children: [
      {
        path: "lottery-list",
        name: "抽奖列表",
        isShow: true,
      },
      // {
      //   path: "number-lottery",
      //   name: "号码抽奖",
      //   isShow: true
      // },
      {
        path: "lottery-detail",
        name: "抽奖列表详情",
        isShow: false,
      },
      {
        path: "lottery-records",
        name: "中奖列表",
        isShow: true,
      },
      {
        path: "lottery-prize",
        name: "奖品列表",
        isShow: true,
      },
      {
        path: "lottery-prize-detail",
        name: "奖品列表详情",
        isShow: false,
      },
    ],
  },
  {
    path: "/random-code-list",
    title: "/random_code/admin",
    name: "随机码管理",
    icon: "el-icon-key",
    catalogue: true,
    children: [
      {
        path: "random-code-batch-list",
        name: "随机码批次",
        isShow: true,
      },
      {
        path: "random-code-batch-detail",
        name: "随机码批次详情",
        isShow: false,
      },
      {
        path: "random-code-list",
        name: "随机码",
        isShow: true,
      },
      {
        path: "random-code-detail",
        name: "随机码详情",
        isShow: false,
      },
      {
        path: "random-code-record-list",
        name: "随机码查询记录",
        isShow: true,
      },
      {
        path: "random-code-record-detail",
        name: "随机码查询记录详情",
        isShow: false,
      },
    ],
  },
  {
    path: "/redeem-code-list",
    title: "/redeem_code/admin",
    name: "兑奖码管理",
    icon: "el-icon-key",
    catalogue: true,
    children: [
      {
        path: "redeem-code-batch-list",
        name: "兑奖码批次",
        isShow: true,
      },
      {
        path: "redeem-code-batch-detail",
        name: "兑奖码批次详情",
        isShow: false,
      },
      {
        path: "redeem-code-list",
        name: "兑奖码列表",
        isShow: true,
      },
      {
        path: "redeem-code-detail",
        name: "兑奖码详情",
        isShow: false,
      },
    ],
  },
];
export default {
  name: "Home",
  data: function () {
    return {
      loading: false,
      avatar: "",
      userInfo: {},
      aside_list: [],
      authRoute: {},
      enableRoute: [],
      isCollapse: false,
    };
  },
  created: function () {
    let _me = this;

    let currentPath = _me.$route.path;
    _me.authRoute = new Map();
    _me.enableRoute = new Map();

    // 获取用户信息
    let userInfo = _me.$common.getGlobalStorage("_user");
    if (!userInfo) {
      _me.$router.push({ path: "/sign-in" });
      return;
    }

    let authList = userInfo.api_auth_category_list;
    if (authList && authList.length > 0) {
      _me.userInfo = userInfo;
      authList.forEach((e) => {
        _me.authRoute.set(e.identifier, 1);
      });

      // 根据登录返回的权限列表进行筛选对应权限的路由列表
      route_list.forEach((v) => {
        if (_me.authRoute.get(v.title) === 1) {
          _me.aside_list.push(v);

          let child = v.children;
          //   一级目录下的路径
          if (child && child.length > 0) {
            child.forEach((item) => {
              _me.enableRoute.set("/" + item.path, 1);
              //   二级目录下的路径
              if (item.children && item.children.length > 0) {
                item.children.forEach((e) => {
                  _me.enableRoute.set("/" + e.path, 1);
                });
              }
            });
          }
        }
      });

      if (_me.enableRoute.get(currentPath) !== 1) {
        // 跳转到新侧边栏列表的第一个
        let path = "";
        if (_me.aside_list[0].children) {
          path = _me.aside_list[0].children[0].path;
        } else {
          path = _me.aside_list[0].path;
        }
        _me.$router.push({ path: "/" + path });
        return;
      }

      if (
        _me.$common.getGlobalStorage("_user") &&
        _me.$common.getGlobalStorage("_user").type == 20
      ) {
        _me.aside_list.forEach((element) => {
          if (element.path == "/after-sales-list") {
            element.catalogue = false;
          }
        });
      }
    } else {
      _me.$router.push({ path: "/sign-in" });
      _me.$message({
        message: "未分配角色，请联系皮总！",
        type: "error",
      });
    }
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case "0":
          this.$router.push("/user-update-info");
          break;
        case "1":
          this.$router.push("/user-update-password");
          break;
        case "2":
          this.$router.push("/about-system");
          break;
        case "3":
          this.signOutOnclick();
          break;
        case "4":
          this.clearCache();
          break;
        case "5":
          this.updateApi();
          break;
        default:
          break;
      }
    },
    signOutOnclick() {
      let _me = this;
      _me
        .$confirm("退出登录系统, 是否继续?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
        .then(() => {
          _me.signOut();
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("取消");
        });
    },
    signOut: function () {
      this.$common.removeGlobalStorage("_user");
      this.$router.push("/sign-in");
      setTimeout(function () {
        window.location.reload();
      }, 50);
    },
    clearCache() {
      // 遍历localstorage对象，除去用户信息，其余的数据都清除缓存
      Object.keys(localStorage).forEach((i) => {
        if (i.indexOf(this.$common.getGlobalStorageKeyName("_user")) === -1) {
          localStorage.removeItem(i);
        }
      });
      this.$router.push("/");
    },
    updateApi() {
      let _me = this;
      _me.loading = true;
      _me.$common
        .httpGet("/api/root/auth/manage/renew")
        .then((res) => {
          _me.loading = false;
          _me.$message.success("更新接口成功");
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
  },
};
</script>

<style>
.g-home_container {
  height: calc(100vh - 60px);
}

.user-info {
  /* margin-top: 8px; */
  display: inline-block;
  float: right;
  color: #ffffff;
}

.info-text {
  float: left;
  padding-right: 10px;
}

.info-text-row {
  display: block;
  text-align: right;
  line-height: 20px;
}

.header-main-frame {
  width: calc(100% - 200px);
}
</style>
